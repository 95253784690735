<template>
  <b-card>
    <header-table title="المعالم الرئيسية" @create="onAdd" />
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="6">
          <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
        </b-col>
        <b-col cols="6">
          <form-select :reduce="(el) => el.value" label="" v-model="status" placeholder="حالة المكان..." class="w-50"
            :options="options" />
        </b-col>
      </b-row>
    </div>
    <data-table ref="estatesTable" :fields="fields" ep="/places/main-places" :filter.sync="filter" :utils.sync="utils">
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <b-row>
            <b-col cols="4">
              <b-carousel id="carousel-example-generic" controls indicators>
                <b-carousel-slide v-for="(item, i) in row.item.images" :key="i" :img-src="item.url"
                  style="height: 230px" />
              </b-carousel>
            </b-col>
            <b-col cols="5">
              <h6>التفاصيل</h6>
              <p>
                {{ row.item.description }}
              </p>
            </b-col>
          </b-row>
        </b-collapse>
      </template>
      <template #cell(actions)="row">
        <b-button variant="flat-info" title="عرض المزيد من المعلومات" size="sm" v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          class="" v-b-tooltip.hover.v-info.top @click="row.toggleDetails" v-b-toggle.collapse-1>
          المزيد <feather-icon icon="ChevronDownIcon" />
          <!-- ChevronUpIcon -->
        </b-button>
        <ActionsTable @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)"></ActionsTable>

      </template>
      <template #cell(status)="row">
        <b-badge style="width: 75px" :variant="statusMap(row.item.status).color">{{ statusMap(row.item.status).text
        }}</b-badge>
      </template>
    </data-table>
    <form-modal ref="estateModalMap" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel" :utils="utils"
      :loading="loading" @confirm="onModalConfirmed">
      <template #long>
        <div>
          <b-row>
            <b-col cols="6">
              <form-input label="خط العرض" v-model="markerPos.lat" rules="required" />
            </b-col>
            <b-col cols="6">
              <form-input label="خط الطول" v-model="markerPos.lng" rules="required" />
            </b-col>
            <b-col cols="12"><b-button class="w-100 outline" variant="outline-primary" @click="mapModal = true">
                <feather-icon icon="MapIcon" /> </b-button></b-col>
          </b-row>
          <b-modal v-model="mapModal" centered size="lg" title="" footer-class="removed" class="edit-modal">
            <select-map @markerUpdated="onLocationSelect" :lat="markerPos.lat" :lng="markerPos.lng">
            </select-map>
            <LoaderBtn variant="primary" @click="mapModal = false"></LoaderBtn>
          </b-modal>
        </div>
      </template>

      <template #status_date="item">
        <form-datepicker v-if="item.form.status != 'D'" v-model="item.form.status_date" label="تاريخ الترميم"
          placeholder="YY-MM-DD" />
      </template>
      <template #images="{form}">
        <form-file
              label="صورة المكان" 
              :rules="form.id ? '' : 'required'"
              accept="image/*" 
              v-model="imageSlide" 
              id="form-file" name="image"
              placeholder="لم تقم باختيار صور" 
              multiple 
              @change="previewImage"
        />
        <div class="text-center mb-1">
          <div class="
              d-flex
              justify-content-between
              shadow shadow-success shadow-intensity-lg
              align-items-center
              my-1
              border
              rounded-lg
              border-success
            " v-for="(item, i) in staticImages" :key="i">
            <b-img :src="item.url" fluid-grow style="width: 50px !important; height: 50px" class="m-1 rounded" />
            <span>
              {{ item.name }}
            </span>
            <b-button variant="flat-danger" class="btn-icon rounded-circle" @click="deleteImage(item)">
              <feather-icon size="15" icon="Trash2Icon" />
            </b-button>
          </div>
          <div class="
              d-flex
              justify-content-between
              shadow
              align-items-center
              my-1
              border
              rounded-lg
            " v-for="(item, i) in deleted_last_images" :key="i">
            <b-img :src="item.url" fluid-grow style="width: 50px !important; height: 50px" class="m-1 rounded" />
            <span>
              <!-- {{ item.name }} -->
            </span>
            <!-- <b-button variant="flat-danger" class="btn-icon rounded-circle" @click="deleteLastImage(item)">
              <feather-icon size="15" icon="Trash2Icon" />
            </b-button> -->
          </div>
        </div>
      </template>
    </form-modal>
  </b-card>
</template>
<script>
import FormSelect from "@/components/form-select/index.vue";

import {
  BCard,
  BButton,
  VBTooltip,
  BCollapse,
  VBToggle,
  BRow,
  BCol,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BDropdown,
  BDropdownItem,
  BBadge,
} from "bootstrap-vue";
import FormModal from "@/components/FormModal.vue";
import DataTable from "@/components/data-table/index";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapActions, mapGetters } from "vuex";
import FormFile from "@/components/form-file/index.vue";
import FormInput from "@/components/form-input/index";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import SelectMap from "@/components/select-map/index.vue";
import LoaderBtn from "@/components/loader-btn.vue";
import FormDatepicker from "@/components/form-datepicker";
import HeaderTable from "@/components/data-table/components/header-table.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue";

export default {
  components: {
    SelectMap,
    LoaderBtn,
    BCard,
    BButton,
    DataTable,
    VBTooltip,
    AppCollapse,
    AppCollapseItem,
    BCollapse,
    FormModal,
    FormFile,
    BRow,
    BCol,
    FormInput,
    BCarousel,
    BCarouselSlide,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BImg,
    FormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    FormDatepicker,
    HeaderTable,
    ActionsTable
},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      options: [
        { label: "مرمم", value: "R" },
        { label: "مدمر", value: "D" },
        { label: "قيد الترميمم", value: "UR" },
      ],
      markerPos: {
        lng: "37.16204839923488",
        lat: "36.19846891224607",
      },
      mapModal: false,
      status: "",
      utils: { status: "" },
      imageSlide: [],
      staticImages: [],
      deleted_images: [],
      deleted_last_images: [],
      fields: [
        { key: "name", label: "اسم المكان ", sortable: true },
        { key: "status", label: "الحالة" },
        { key: "status_date", label: "تايخ الترميم" },
        { key: "actions", label: "" },
      ],
      activModel: false,
      filter: "",
      estatesFormSchema: {
        name: {
          component: "FormInput",
          key: "name",
          attrs: {
            id: "cladding_type",
            label: "اسم المكان",
            rules: "required",
          },
        },
        status: {
          component: "FormSelect",
          key: "status",
          attrs: {
            id: "status",
            label: "الحالة",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "مرمم", value: "R" },
              { label: "مدمر", value: "D" },
              { label: "قيد الترميمم", value: "UR" },
            ],
          },
        },

        tag_ids: {
          component: "FormSelect",
          key: "tag_ids",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.tag,
            id: "tag_ids",
            label: "الكلمات المفتاحية",
            ep: "select/archive-tags",
            rules: "required",
            multiple: true
          },
        },

        description: {
          component: "FormTextarea",
          key: "description",
          attrs: {
            label: "التفاصيل",
            rules: "required",
          },
        },
        status_date: {
          key: "status_date",
        },
        long: {
          key: "long",
        },

        images: {
          key: "images",
        },
      },
    };
  },

  methods: {
    ...mapActions("manageApp/interactiveMap", [
      "update",
      "create",
      "delete",
    ]),
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف المكان", {
          title: "تأكيد حذف المكان",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ id: item.id, slug: "main-places" }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
    onAdd() {
      this.$refs.estateModalMap.init({});
      this.activModel = true;
      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
      this.deleted_last_images = [];
    },
    deleteImage(item) {
      this.imageSlide = this.imageSlide.filter(
        (file) => file.name !== item.name
      );
      this.staticImages = this.staticImages.filter(
        (file) => file.name !== item.name
      );
    },
    deleteLastImage(item) {
      this.deleted_images.push(item.id);
      this.deleted_last_images = this.deleted_last_images.filter(
        (data) => data.id != item.id
      );
    },
    previewImage(e) {
      this.staticImages = Array.from(e.target.files).map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
      }));
    },
    onLocationSelect(data) {
      this.markerPos.lng = data.lng;
      this.markerPos.lat = data.lat;
    },


    
    onUpdateClicked(form) {
      
      var toUpdate = {
        id: form.id,
        name: form.name,
        description: form.description,
        status: form.status,
        status_date: form.status_date,
        tag_ids: form.archiveTags.map((item=>item.id))
      };
      this.markerPos.lng = form.longitude;
      this.markerPos.lat = form.latitude;
      this.deleted_last_images = form.images;
      this.$refs.estateModalMap.init(toUpdate);
      this.activModel = true;

      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
    },

    
    onModalConfirmed(form) {
      var fd = new FormData();
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          const element = form[key];
          if (key === 'tag_ids') {
            for (let i = 0; i <= element.length; i++) {
              if(element[i])
                fd.append("tag_ids[]", element[i]);
            }
          }
          else if (key == "status_date") {
            if (form.status != 'D') {
              fd.append(key, element)
            }
          }

          else if (key == "price") {
            fd.append(key, element.replace(/\D/g, ""));
          } else fd.append(key, element);
        }
      }

      fd.append("longitude", this.markerPos.lng);
      fd.append("latitude", this.markerPos.lat);
      if (this.imageSlide.length !== 0)
        for (let i = 0; i <= this.imageSlide.length; i++) {
          fd.append("images[]", this.imageSlide[i]);
        }
      if (this.deleted_images.length !== 0)
        for (let i = 0; i < this.deleted_images.length; i++) {
          fd.append("deleted_images[]", this.deleted_images[i]);
        }
      if (form.id) {
        fd.append("_method", "PUT");
        this.update({ id: form.id, fd: fd, slug: "main-places" }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ fd: fd, slug: "main-places" }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },

    statusMap(val) {
      var map = {
        text: "",
        color: "",
      };
      if (val === "R") {
        map.text = "مرمم";
        map.color = "light-success";
      } else if (val == "D") {
        map.text = "مدمر";
        map.color = "light-danger";
      } else if (val === "UR") {
        map.text = "قيد الترميمم";
        map.color = "light-primary";
      }
      return map;
    },
  },
  computed: {
    ...mapGetters("manageApp/interactiveMap", ["loading"]),
  },

  watch: {
    status(val) {
      this.utils.status = val;
      this.$refs.estatesTable.refreshTable();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/map-leaflet.scss";
</style>
